<template>
  <div
      class="city-card"
      @click="goToEdit"
  >
    <div class="city-card-edit">
      <edit-icon/>
    </div>
    <div class="overlay"/>

    <div class="city-card-info">
      <h4 class="name">
        {{ destination.title }}
      </h4>

      <div class="places">
        <div>{{ destination.services }}</div>
        <div>{{ $t("common.place") }}</div>
      </div>
    </div>

    <img
        :src="destination.image"
        :alt="destination.title"
    >

  </div>
</template>

<script>

export default {
  props: {
    destination: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    goToEdit() {
      this.$router.push({
        name: 'updateDestination',
        params: { lang: this.$i18n.locale, id: this.destination.id },
      });
    },
  },
};
</script>
