<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.destination.title')"
        :sub-title="$t('settings.destination.desc')"
        :btn-title="$t('settings.destination.new_btn')"
        route-name="addDestination"
    />


    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <div class="w-table d-flex flex-column settings-destinations">
            <div class=" settings-destinations-grid">
              <div
                  v-for="destination in getAllDestinations"
                  :key="destination.id"
                  class="setting-destination-card"
              >
                <destination-card :destination="destination"/>
              </div>
            </div>
            <wameed-no-data
                v-if="getAllDestinations && getAllDestinations.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <wameed-pagination
              v-if="getTotalDestinations"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalDestinations.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import DestinationCard from "@/views/pages/settings/destinations/components/DestinationCard";

export default {
  components: {
    DestinationCard,
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        page: 1,
        per_page: 10,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllDestinations: 'getAllDestinations',
      getTotalDestinations: 'getTotalDestinations',
    }),
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadDestinations: 'loadDestinations',
      toggleDestination: 'toggleDestination',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadDestinations(this.filterData);
    },
    DestinationDetails(id) {
      this.$router.push({
        name: 'updateDestination',
        params: {lang: this.$i18n.locale, id},
      });
    },

  },
};
</script>
